var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('div',{staticClass:"d-flex align-center"},[_c('v-row',{staticClass:"justify-space-between ma-2"},[_c('div',[_c('h3',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.title))])]),_c('div',[_c('div',{staticClass:"row d-flex align-center"},[_c('InformationDDC',{attrs:{"redPercent":25}}),_c('v-btn',{attrs:{"icon":"","color":"green","large":""},on:{"click":_vm.exportData}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1)])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isLoading,"disable-pagination":"","disable-sort":"","hide-default-footer":"","dense":"","fixed-header":"","height":_vm.maxtableHeight},scopedSlots:_vm._u([_vm._l((_vm.headers.filter((header) =>
        header.hasOwnProperty('formatter')
      )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.plasma_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.plasma_Count_Perc_Diff, 25) ? 'primary--text' : ''},[_vm._v(" "+_vm._s(item.plasma_Count_Perc_Diff !== null ? _vm.formatValue(item.plasma_Count_Perc_Diff) : "")+" ")])]}},{key:"item.cryoprecipitate_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.cryoprecipitate_Count_Perc_Diff, 25)
            ? 'primary--text'
            : ''},[_vm._v(" "+_vm._s(item.cryoprecipitate_Count_Perc_Diff !== null ? _vm.formatValue(item.cryoprecipitate_Count_Perc_Diff) : "-")+" ")])]}},{key:"item.pdC_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.pdC_Count_Perc_Diff, 25) ? 'primary--text' : ''},[_vm._v(" "+_vm._s(item.pdC_Count_Perc_Diff !== null ? _vm.formatValue(item.pdC_Count_Perc_Diff) : "-")+" ")])]}},{key:"item.rC_ex_phl_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.rC_ex_phl_Count_Perc_Diff, 25) ? 'primary--text' : ''},[_vm._v(" "+_vm._s(item.rC_ex_phl_Count_Perc_Diff !== null ? _vm.formatValue(item.rC_ex_phl_Count_Perc_Diff) : "-")+" ")])]}},{key:"item.ddavP_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.ddavP_Count_Perc_Diff, 25) ? 'primary--text' : ''},[_vm._v(" "+_vm._s(item.ddavP_Count_Perc_Diff !== null ? _vm.formatValue(item.ddavP_Count_Perc_Diff) : "-")+" ")])]}},{key:"item.emicizumab_inhibitor_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.emicizumab_inhibitor_Count_Perc_Diff, 25)
            ? 'primary--text'
            : ''},[_vm._v(" "+_vm._s(item.emicizumab_inhibitor_Count_Perc_Diff !== null ? _vm.formatValue(item.emicizumab_inhibitor_Count_Perc_Diff) : "-")+" ")])]}},{key:"item.emicizumab_ex_inhibitor_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.emicizumab_ex_inhibitor_Count_Perc_Diff, 25)
            ? 'primary--text'
            : ''},[_vm._v(" "+_vm._s(item.emicizumab_ex_inhibitor_Count_Perc_Diff !== null ? _vm.formatValue(item.emicizumab_ex_inhibitor_Count_Perc_Diff) : "-")+" ")])]}},{key:"item.rC_phl_Count_Perc_Diff",fn:function({ item }){return [_c('span',{class:_vm.highlight(item.rC_phl_Count_Perc_Diff, 25) ? 'primary--text' : ''},[_vm._v(" "+_vm._s(item.rC_phl_Count_Perc_Diff !== null ? _vm.formatValue(item.rC_phl_Count_Perc_Diff) : "-")+" ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }